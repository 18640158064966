import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useFetcher, useLoaderData } from '@remix-run/react';
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node';
import { redirect, json } from '@remix-run/node';
import invariant from 'tiny-invariant';
import { twMerge } from 'tailwind-merge';
import { uuid4 } from '@sentry/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons';

import { Header, AuthOption, Footer } from '~/components/authorize/authorize-box';

import { InputField } from '~/components/input-field';
import {
  type SessionValues,
  checkUserSession,
  createRedirectSessionHeaders,
  createInviteSessionHeaders,
  createUserEmailSession,
  getLoginKey,
} from '~/session/session.server';
import { getWebAuth } from '~/lib/auth0.client';
import { getInviteDetails } from '~/services/organizations.server';
import { authenticationEmailStart, enterpriseConnectionExists } from '~/services/auth.server';
import { InfoBanner, InfoBannerText } from '~/components/info-banner';
import { Button, Link, Separator } from 'react-aria-components';
import { ToastContainer, type TypeOptions, toast as notify } from 'react-toastify';
import { mergeHeaders } from '~/services/headers.server';

type Toast = {
  message: string;
  type: TypeOptions;
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await checkUserSession(request);

  // Get the query parameters
  const url = new URL(request.url);
  const searchParams = url.searchParams;

  // Get the invitation and organization from the query parameters!!
  const ticketId = searchParams.get('invitation');
  const organization = searchParams.get('organization');
  let inviteeEmail = searchParams.get('md');
  const redirectParam = searchParams.get('redirect');

  // Replace spaces with + in the email if it exists
  if (inviteeEmail) {
    inviteeEmail = inviteeEmail.replace(' ', '+');
  }

  let headers = new Headers();
  let toast: Toast | null = null;

  // If the user is not logged
  if (!user) {
    // If there is an invitation, get the ticket
    if (ticketId && organization && inviteeEmail) {
      const ticket = await getInviteDetails(ticketId, organization, inviteeEmail);

      // If there is an invitation, return the ticket
      if (ticket) {
        if (ticket.accepted) {
          toast = { message: 'You have already accepted this invitation, try to log in.', type: 'info' };
          return json({ ticket, toast }, { headers });
        }

        // Create session values array with empty array
        let sessionValues: SessionValues[] = [];

        // Add invitation and organization to session
        if (ticketId && organization && inviteeEmail) {
          sessionValues.push({ name: 'invitation', value: ticketId });
          sessionValues.push({ name: 'organization', value: organization });
          sessionValues.push({ name: 'inviteeEmail', value: inviteeEmail });

          // Create session headers
          headers = await createInviteSessionHeaders({ request, sessionValues });
        }

        return json({ ticket, toast }, { headers });
      } else {
        toast = {
          message: 'This invitation has already been declined, expired or revoked by the admin',
          type: 'error',
        };
        return json({ ticket: null, toast }, { headers });
      }
    }

    if (redirectParam) {
      const redirectHeaders = await createRedirectSessionHeaders({ request, redirectTo: redirectParam });

      const mergedHeaders = mergeHeaders(headers, redirectHeaders);

      return json({ ticket: null, toast }, { headers: mergedHeaders });
    }

    // If there is no invitation, return no ticket
    return json({ ticket: null, toast }, { headers });
  }

  // Get the login key
  const loginKey = await getLoginKey(request);

  // If the user is logged in, redirect to the enter passphrase page
  if (loginKey) {
    return redirect('/app/authorize/enter-passphrase', { headers });
  }

  // TODO - We can keep this for now but we should remove it later. Mpw we can keep it to support the old email urls that are still in use
  // If the user is logged in and there is no login key, and there is an invitation, get the ticket
  if (ticketId && organization && inviteeEmail) {
    const ticket = await getInviteDetails(ticketId, organization, inviteeEmail);

    // If there is an invitation, return the ticket
    if (ticket) {
      if (ticket.accepted) {
        toast = { message: 'You have already accepted this invitation, try to log in.', type: 'info' };
        return json({ ticket, toast }, { headers });
      }

      return json({ ticket, toast }, { headers });
    } else {
      toast = {
        message: 'This invitation has already been declined, expired or revoked by the admin',
        type: 'error',
      };
      return json({ ticket: null, toast }, { headers });
    }
  }

  return json({ ticket: null, toast }, { headers });
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const action = formData.get('action');

  invariant(action, 'Action is required');

  if (action === 'email') {
    const email = (formData.get('email') as string)?.toLowerCase();
    invariant(typeof email === 'string', 'Email is required');

    const response = await authenticationEmailStart(request, email);

    if (response.status === 403) {
      return await enterpriseConnectionExists(request, email, '');
    }

    if (!response.ok) {
      const { message } = await response.json();
      return json({ errors: [message] }, response.status);
    }

    return createUserEmailSession({
      request,
      email,
      redirectTo: '/app/authorize/email',
    });
  }

  if (action === 'sso') {
    const email = (formData.get('email') as string)?.toLowerCase();
    const domain = (formData.get('domain') as string)?.toLowerCase();

    return await enterpriseConnectionExists(request, email, domain);
  }

  return null;
}

type EmailFormProps = {
  loading: boolean;
  disabled: boolean;
  error?: string;
  defaultValue?: string;
  onResetError: () => void;
};

function EmailForm({ loading, error, disabled, defaultValue, onResetError }: EmailFormProps) {
  return (
    <>
      <Separator className="my-1 h-[1px] bg-gray-500" />
      <input id="emailAction" hidden readOnly value="email" name="action" />
      <InputField
        isDisabled={loading}
        id="email"
        autoFocus
        name="email"
        type="email"
        defaultValue={defaultValue}
        onChange={onResetError}
        isRequired
        label="Email"
        className="mt-[20px]"
        isInvalid={Boolean(error)}
        errorMessage={error}
      />
      <div className="pt-4">
        <AuthOption disabled={disabled} loading={loading} icon="envelope" type="submit">
          Continue with Email
        </AuthOption>
      </div>
    </>
  );
}

type SsoFormProps = {
  loading: boolean;
  disabled: boolean;
  error?: string;
  defaultValue?: string;
  onResetError: () => void;
};

function SsoForm({ loading, disabled, error, defaultValue, onResetError }: SsoFormProps) {
  return (
    <>
      <Separator className="my-1 h-[1px] bg-gray-500" />
      <input id="ssoAction" hidden readOnly value="sso" name="action" />
      <div className={twMerge(error ? 'pb-4' : 0)}>
        <InputField
          id="email"
          autoFocus
          isDisabled={loading || disabled}
          onChange={onResetError}
          defaultValue={defaultValue}
          name="email"
          type="email"
          isRequired
          label="Company Email"
          className="mt-[20px]"
          isInvalid={Boolean(error)}
          errorMessage={error}
        />
      </div>
      <div className="pt-4">
        <AuthOption type="submit" disabled={loading || disabled} loading={loading} icon="key">
          Continue with Enterprise SSO
        </AuthOption>
      </div>
    </>
  );
}

type SocialConnection = 'google-oauth2' | 'github';

export default function Authorize() {
  const { ticket, toast } = useLoaderData<typeof loader>();

  const emailFetcher = useFetcher<typeof action>();
  const emailLoading = emailFetcher.state !== 'idle';

  const ssoFetcher = useFetcher<typeof action>();
  const ssoLoading = ssoFetcher.state !== 'idle';

  const [connectionError, setConnectionError] = useState<string | undefined>();
  const [connection, setConnection] = useState<SocialConnection | undefined>();
  const [continueWithEmail, setContinueWithEmail] = useState(!!ticket?.invitedEmail || false);
  const [continueWithSso, setContinueWithSso] = useState(false);
  const [searchParams] = useSearchParams();

  const [emailError, setEmailError] = useState<any>();
  const [ssoError, setSsoError] = useState<any>();

  // Hook to show the toasts
  useEffect(() => {
    if (toast) {
      // notify on a toast message
      notify(toast.message, { type: toast.type as TypeOptions, toastId: uuid4() });
    }
  }, [toast]);

  useEffect(() => {
    if (
      emailFetcher.state === 'idle' &&
      emailFetcher.data !== null &&
      emailFetcher.data &&
      'errors' in emailFetcher.data
    ) {
      setEmailError(emailFetcher.data?.errors);
    }
  }, [emailFetcher.state, emailFetcher.data]);

  useEffect(() => {
    if (ssoFetcher.state === 'idle' && ssoFetcher.data != null && ssoFetcher.data && 'errors' in ssoFetcher.data) {
      setSsoError(ssoFetcher.data?.errors);
    }
  }, [ssoFetcher.state, ssoFetcher.data]);

  const handleContinueWithGoogle = useCallback(() => {
    setContinueWithEmail(false);
    setContinueWithSso(false);
    setConnection('google-oauth2');
    try {
      const webAuth = getWebAuth({ invitedEmail: ticket?.invitedEmail });
      webAuth?.authorize({ connection: 'google-oauth2' });
    } catch (_e: unknown) {
      setConnectionError('Something went wrong!');
    }
  }, [ticket?.invitedEmail]);

  const handleContinueWithGithub = useCallback(() => {
    setContinueWithEmail(false);
    setContinueWithSso(false);
    setConnection('github');
    try {
      const webAuth = getWebAuth({ invitedEmail: ticket?.invitedEmail });
      webAuth?.authorize({ connection: 'github' });
    } catch (_e: unknown) {
      setConnectionError('Something went wrong!');
    }
  }, [ticket?.invitedEmail]);

  const handleContinueWithEmail = useCallback(() => {
    setContinueWithSso(false);
    setContinueWithEmail((showEmail) => !showEmail);
  }, []);

  const handleContinueWithSso = useCallback(() => {
    setContinueWithEmail(false);
    setContinueWithSso((showSso) => !showSso);
  }, []);

  const handleOauthErrorClear = () => {
    setConnection(undefined);
    setConnectionError(undefined);
    handleResetError();
  };

  const handleResetError = useCallback(() => {
    setEmailError(undefined);
    setSsoError(undefined);
  }, []);

  const handleContinueWithProvider = useCallback((provider: string) => {
    if (provider === 'google') {
      setContinueWithEmail(false);
      setContinueWithSso(false);
      setConnection('google-oauth2');
      try {
        const webAuth = getWebAuth({ invitedEmail: undefined });
        webAuth?.authorize({ connection: 'google-oauth2' });
      } catch (_e: unknown) {
        setConnectionError('Something went wrong!');
      }

      sessionStorage.removeItem('provider');
      return;
    }

    if (provider === 'github') {
      setContinueWithEmail(false);
      setContinueWithSso(false);
      setConnection('github');
      try {
        const webAuth = getWebAuth({ invitedEmail: undefined });
        webAuth?.authorize({ connection: 'github' });
      } catch (_e: unknown) {
        setConnectionError('Something went wrong!');
      }

      sessionStorage.removeItem('provider');
      return;
    }

    if (provider === 'email') {
      setContinueWithSso(false);
      setContinueWithEmail((showEmail) => !showEmail);
      sessionStorage.removeItem('provider');
      return;
    }

    if (provider === 'sso') {
      setContinueWithEmail(false);
      setContinueWithSso((showSso) => !showSso);
      return;
    }
  }, []);

  useEffect(() => {
    handleResetError();
    const provider = sessionStorage.getItem('provider');
    if (provider) {
      handleContinueWithProvider(provider);
    }
  }, [handleResetError, handleContinueWithProvider]);

  const authCode = searchParams.get('code');
  const success = searchParams.get('success')?.toLocaleLowerCase() === 'true';
  const message = searchParams.get('message');
  const hasCode = Boolean(authCode);
  const shouldDisable = hasCode || ssoLoading || emailLoading || ssoLoading;

  useEffect(() => {
    const sso = ssoFetcher.data;

    if (ssoFetcher.state !== 'idle') {
      return;
    }

    if (!sso || !('connectionName' in sso)) {
      return;
    }

    const connectionName = sso && 'connectionName' in sso ? sso.connectionName : undefined;
    const webAuth = getWebAuth({ invitedEmail: ticket?.invitedEmail });
    webAuth?.authorize({ connection: connectionName });
  }, [ssoFetcher, ticket?.invitedEmail]);

  useEffect(() => {
    const sso = emailFetcher.data;

    if (emailFetcher.state !== 'idle') {
      return;
    }

    if (!sso || !('connectionName' in sso)) {
      return;
    }

    const connectionName = sso && 'connectionName' in sso ? sso.connectionName : undefined;

    // Reset the fetcher data
    emailFetcher.load('/app/authorize');

    const webAuth = getWebAuth({ invitedEmail: ticket?.invitedEmail });
    webAuth?.authorize({ connection: connectionName });
  }, [emailFetcher, ticket?.invitedEmail]);

  useEffect(() => {
    if (ticket?.connectionName) {
      const webAuth = getWebAuth({ invitedEmail: ticket?.invitedEmail });
      webAuth?.authorize({ connection: ticket?.connectionName });
    }
  }, [ticket]);

  return (
    <>
      <Header>Welcome to Insomnia</Header>
      {connectionError && (
        <div className="mb-4">
          <div className="rounded-sm border border-red-200 bg-[#fbeff2] px-[16px] py-[6px] text-red-600">
            <div className="flex flex-grow justify-between gap-3">
              <FontAwesomeIcon icon={faCircleXmark} className="h-5 w-5" />
              <div className="flex flex-1">
                <p className="text-sm text-[#562734]">{message}</p>
              </div>
              <Button aria-label="close" onPress={handleOauthErrorClear} className="self-start">
                <FontAwesomeIcon icon={faXmark} className="h-4 w-4 text-[#562734]" />
              </Button>
            </div>
          </div>
        </div>
      )}
      {success && message && (
        <div className="mb-4">
          <InfoBanner>
            <InfoBannerText>{message}</InfoBannerText>
          </InfoBanner>
        </div>
      )}
      <AuthOption
        icon="google"
        onClick={handleContinueWithGoogle}
        loading={connection === 'google-oauth2'}
        disabled={shouldDisable}
      >
        Continue with Google
      </AuthOption>
      <AuthOption
        icon="github"
        loading={connection === 'github'}
        disabled={shouldDisable}
        onClick={handleContinueWithGithub}
      >
        Continue with GitHub
      </AuthOption>
      {!continueWithEmail && (
        <AuthOption icon="envelope" onClick={handleContinueWithEmail} disabled={shouldDisable}>
          Continue with Email
        </AuthOption>
      )}
      {!continueWithSso && (
        <AuthOption icon="key" onClick={handleContinueWithSso} disabled={shouldDisable}>
          Continue with Enterprise SSO
        </AuthOption>
      )}
      {continueWithEmail && (
        <emailFetcher.Form method="POST">
          <EmailForm
            disabled={shouldDisable}
            loading={emailLoading || ssoLoading}
            error={emailError || ssoError}
            defaultValue={ticket?.invitedEmail}
            onResetError={() => setEmailError(undefined)}
          />
        </emailFetcher.Form>
      )}
      {continueWithSso && (
        <ssoFetcher.Form method="POST">
          <SsoForm
            disabled={shouldDisable}
            loading={ssoLoading}
            error={ssoError}
            defaultValue={ticket?.invitedEmail}
            onResetError={() => setSsoError(undefined)}
          />
        </ssoFetcher.Form>
      )}
      <Footer>
        By signing up, you agree to the{' '}
        <Link target="_blank" href="https://insomnia.rest/terms" className="text-gray-500 underline">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link target="_blank" href="https://insomnia.rest/privacy" className="text-gray-500 underline">
          Privacy Policy agreement
        </Link>
        .
      </Footer>
      <ToastContainer position="bottom-right" stacked />
    </>
  );
}
